$fonts = '/fonts/'
@font-face {
    font-family: 'Icons';
    src: url($fonts + 'iconfont.eot'); /* IE9 Compat Modes */
    src: url($fonts + 'iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + 'iconfont.ttf') format('ttf'), /* Modern Browsers */
         url($fonts + 'iconfont.woff') format('woff'); /* Modern Browsers */
}

.icon:before {
    font-family: 'Icons';
    speak: none;
    font-size:100%;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height:1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon:before
    .html &
        content "\ea02"
    .css &
        content "\ea01"
    .js &
        content "\ea03"
    .rwd &
        content "\ea05"
    .php &
        content "\ea04"