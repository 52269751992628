.contact-details
	.container
		font-size 0
		text-align justify
		&:after
			width 100%
			display inline-block
			content ""
.details
	background-color $mid-grey
	color white
	overflow hidden
	h1
		color white
		font-size 50px
		margin 0.5em 0
		@media $mobilebreakpoint
			margin 0.7em 0
	&--item
		display inline-block
		vertical-align middle
		font-size 1rem
	&_job
		font-size 26px
		font-weight 600
		margin-bottom 0.5em
		@media $mobilebreakpoint
			margin-bottom 0
	&--divider
		position relative
		&--item
			background-color white
			width 10px
			height 300px
			position absolute
			top 50%
			margin-top -150px
			transform rotate(15deg)
			&.left
				left -50px
				@media $mobilebreakpoint
					left -25px
			&.right
				right 0
				@media $mobilebreakpoint
					right -25px
.contact
	background-color $light-grey
	font-weight 500
	color $text-colour-light
	padding 0.5em 0
	a
		font-weight 500
		color $text-colour-light
		text-decoration none
		&:hover
			text-decoration underline
	&--item
		display inline-block
		vertical-align middle
		font-size 0.8rem
		@media $mobilebreakpoint
			font-size 1rem
	&_address
		display none
		@media $mobilebreakpoint
			display inline-block