$fonts = '/fonts/'
$avenir = 'AvenirNextCondensed'
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-Regular.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-Regular.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-Bold.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-Bold.woff') format('woff'); /* Modern Browsers */
    font-weight: bold;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-BoldItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-Italic.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-Italic.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-DemiBold.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-DemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-DemiBold.woff') format('woff'); /* Modern Browsers */
    font-weight: 600;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-DemiBoldItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-DemiBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-DemiBoldItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-Medium.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-Medium.woff') format('woff'); /* Modern Browsers */
    font-weight: 500;
}
@font-face {
    font-family: 'AvenirNextCondensed';
    src: url($fonts + $avenir + '-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts + $avenir + '-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts + $avenir + '-MediumItalic.woff') format('woff'); /* Modern Browsers */
    font-weight: 500;
    font-style: italic;
}