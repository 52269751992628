.experience
	&--item
		overflow auto
		margin-top 2em
		a
			font-weight 600
			text-decoration none
			&:hover
				text-decoration underline
		&--title
			font-size 20px
			margin 0
		&--details
			color $blue
			font-weight 600
			text-align justify
			font-size 0
			&:after
				width 100%
				display inline-block
				content ""
			.col
				font-size 20px
				display inline-block
				vertical-align center
		&--description
			font-weight 500
			margin-top 0.5em
			h4
				margin 1em 0 0
			ul
				margin 0.5em 0 0
				padding 0
				list-style-type
			li
				&:before
					content "\25CF"
					display inline-block
					color $blue
					margin 0 0.5em
			
		&--image
			display none
			@media $mobilebreakpoint
				display block
				float right
				max-width 50%
				margin-left 20px
				img
					max-height 300px
					max-width 300px
					height auto
					width auto
		&_image
			.experience
				&--item
					&--details
						display none
			
		&--link
			margin-top 1em
		&--iframe
			display block
			width 320px
			height 580px
			float left
			margin-right 20px