*
    box-sizing border-box
html
    color $text-colour
    font-family 'AvenirNextCondensed'
    font-weight 500
    a
        color $blue
        text-decoration underline
        font-weight bold
        &:hover
            text-decoration none
    h1
    h2
    h3
    h4
    h5
    h6
        color $blue
        font-weight 600
        
    .wrapper
        margin 1em 0
        @media $tabletbreakpoint
            margin 2em auto
    .container
        max-width 960px
        padding 0 15px
        @media $tabletbreakpoint
            margin 0 auto