color-block($a = $brown-light, $b = $brown, $c = $brown-dark)
    background-color $b
    box-shadow 2px 2px 0 1.5px $a
    .icon
        text-shadow 2px 2px 0 $c
        @media $mobilebreakpoint
            text-shadow 5px 5px 0 $c
            
    .skills-summary
        &--item
            &--score
                &--item
                    background-color $c
                &.one
                    .skills-summary
                        &--item--score--item:nth-child(n+2)
                            background-color $a
                &.two
                    .skills-summary
                        &--item--score--item:nth-child(n+3)
                            background-color $a
                &.three
                    .skills-summary
                        &--item--score--item:nth-child(n+4)
                            background-color $a
                &.four
                    .skills-summary
                        &--item--score--item:nth-child(n+5)
                            background-color $a
                            
                    


$icons = '/images/icons/'

.skills-summary
    font-size 0
    &--item
        &-container
            display inline-block
            width 20%
            font-size 1rem
            padding 10%
            position relative
            margin-bottom 50px
            @media $mobilebreakpoint
                margin-bottom 90px
        border-radius 50%
        width 90%
        height 90%
        position absolute
        top 0
        left 5%
        display inline-block
        .icon
            width 100%
            height 100%
            position absolute
            font-size 8vw
            color white
            display table
            text-align center
            @media $tabletbreakpoint
                font-size 5em
            &:before
                display table-cell
                vertical-align middle
        &--description
            padding-top 100%
        &--type
            font-weight 600
            text-align center
            font-size 30px
            @media $mobilebreakpoint
                font-size 50px
        &--score
            width 60%
            text-align justify
            margin 0 auto
            font-size 0
            &:after
                display inline-block
                width 100%
                content ""
            &--item
                border-radius 50%
                display inline-block
                width 5px
                height 5px
                @media $mobilebreakpoint
                    width 10px
                    height 10px

        &.html
            color-block($green-light,$green,$green-dark)
        &.css
            color-block($blue-light,$blue,$blue-dark)
        &.js
            color-block($turquoise-light,$turquoise,$turquoise-dark)
        &.rwd
            color-block($brown-light,$brown,$brown-dark)
        &.php
            color-block($yellow-light,$yellow,$yellow-dark)